import React from "react";

function Footer() {
    return (
        // footer start
        <div className="w-screen decoration-none text-base md:text-lg text-white">
            <hr></hr>
            <div className="flex justify-around">
                <a href="/ads" className="m-2 hover:underline">
                    Why do we show ads?
                </a>
                <a
                    href="mailto:anonymous@alienx.app"
                    className="m-2 hover:underline"
                >
                    Contact Us
                </a>
                <a href="/donate" className="m-2 hover:underline">
                    Buy us a coffee
                </a>
            </div>
            <div className="flex justify-around">
                <a href="/deleteAccount" className="m-2 hover:underline">
                    Delete Your Account
                </a>
                <a href="/university_response" className="m-2 hover:underline">
                    Our View (App Discouragement)
                </a>
                <a
                    href="https://anmolmalik01.netlify.app/"
                    className="m-2 hover:underline"
                >
                    Special Thanks Anmol Malik
                </a>
            </div>
            <div className="flex justify-around">
                <a href="/privacy_policy" className="m-2 hover:underline">
                    Privacy Policy
                </a>
                <a href="/terms_of_service" className="m-2 hover:underline">
                    Terms of Service
                </a>
            </div>
        </div>
    );
}

export default Footer;
