import React from "react";
import Donations from "./components/Donations";
import Home from "./components/Home";
import WhyShowAds from "./components/WhyShowAds";
import Delete from "./components/AccountDeletion";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import UniversityResponse from "./components/UniversityResponse";
import {PrivacyPolicy, TermsOfService} from "./components/PrivacyPolicy"

const App = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Home/>}></Route>
                <Route exact path="/deleteAccount" element={<Delete/>}></Route>
                <Route exact path="/donate" element={<Donations/>}></Route>
                <Route exact path="/ads" element={<WhyShowAds/>}></Route>
                <Route exact path="/university_response" element={<UniversityResponse/>}></Route>
                <Route exact path="/privacy_policy" element={<PrivacyPolicy/>}></Route>
                <Route exact path="/terms_of_service" element={<TermsOfService/>}></Route>
            </Routes>
        </Router>
    );
};

export default App;
