import React from "react";

const WhyShowAds = () => {
    return (
        <>
            <div className="text-center h-screen text-white flex">
                <p className="h-max mx-6 sm:mx-16 md:mx-36 flex flex-col justify-center">
                <h1 className="text-3xl md:text-5xl mb-6 font-medium"><br/>Why do we show ads?</h1>
                <div className="">
                    <br/>
                    This is to clear any confusion and concerns regarding ads in
                    the application.
                    <br /> 
                    A lot of the users might think that the application
                    is developed by the University. However, that is not the
                    case and the University is not involved in development of
                    the application in any way whatsoever.
                    <br /> 
                    We are also students like you studying in the
                    University who took upon the responsibility ourselves to
                    make it easier and faster to access the University portal
                    CUIMS and came up with the unique idea of this application.
                    <br /> 
                    We need to use multiple cloud services to make the
                    application function properly and smoothly. The cost of
                    these services increases with the amount of users that use
                    the application and we are glad to share that in past few
                    months we have witnessed a significant growth in number of
                    users but with that growth the overall cost of services also
                    increased by significant factor. We have reduced the
                    frequency of ads drastically. We will only cover the cost of
                    cloud services from ads. For revenue we will totally rely on
                    Donations 🧡 from friends like you.
                    <br />
                    Regards <br />
                    Anonymous Aliens
                </div>
                </p>
            </div>
        </>
    );
};

export default WhyShowAds;
