import React from "react";

const UPIButton = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (
        ((/windows phone/i.test(userAgent) ||
        /android/i.test(userAgent) ||
        /iPad|iPhone|iPod/.test(userAgent)) && !window.MSStream)
    ) {
        return (<center>
            <a
                className="btn btn-success mb-3"
                href="upi://pay?mode=02&pa=Q571045502@ybl&pn=Avant-GardeX&purpose=00&mc=8999&orgid=180001&sign=MEQCIBHmiMVoXSPOaP6L+qxi1ptp1Ruz9dESTlf8wGpEoaJOAiAnA1H+LS/H69bRQt1SBwQd3kjZfc5lrdG45BQCiUbpcA=="
            >
            </a>
        </center>);
    }
    return null;
}

const Donations = () => {
        return (
            <>
                <div className="h-screen flex flex-col flex-wrap items-center justify-around">
                    <h1 className="my-14 text-center text-xl md:text-4xl text-white">You can Donate Us 💚</h1>
                    <div className="">
                        <UPIButton />
                        <img
                            src="assets/qrcode.png"
                            // className="img-thumbnail d-block mx-auto animated fadeIn"
                            className="h-[70%]"
                            alt="Donation QR"
                        />
                    </div>
                </div>
            </>
        );
};

export default Donations;
