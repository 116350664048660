import React from "react";

import Main from "./Main"

const Home = () => {

    return (
        <>
            <div className="">
                <Main /> 
            </div>
        </>
    );
};

export default Home;
