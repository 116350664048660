import React from "react";
import github from "../../assets/github.svg";
import apple from "../../assets/apple.svg";
import playStore from "../../assets/google_play_store.svg";
import { ReactSVG } from "react-svg";

const ProjectLayout = (props) => {
    const logos = {
        github: github,
        apple: apple,
        playStore: playStore,
    };

    const items = [];
    props.links.forEach((element) => {
        items.push(
            <div className="grid grid-rows-1 grid-flow-col bg-green-700 hover:bg-sky-700 rounded-2xl m-1">
                <ReactSVG src={logos[element.icon]} className="mt-1 pl-3" />
                <a
                    className="text-base md:text-lg text-white mx-3"
                    href={element.link}
                    target="blank"
                >
                    {element.text}
                </a>
            </div>
        );
    });

    return (
        <div
            className="flex flex-col justify-center w-screen px-6 md:px-24 text-white pt-6 md:pt-10"
            key={props.id}
        >
            <div className="rounded drop-shadow-lg p-7 md:p-10">
                {props.img !== undefined ? (
                    <img
                        src={"assets/" + props.img}
                        className="mx-auto w-[18%] rounded-3xl shadow-2xl hover:shadow-none"
                        alt="Project Logo"
                    />
                ) : (
                    <></>
                )}
                <p className="text-center font-bold text-3xl md:text-6xl py-5 md:py-8 text-white">
                    {props.name}
                </p>
                <p className="text-base text-center md:text-lg md:mx-3 md:mr-5 leading-6 md:leading-7">
                    {props.description}
                </p>
                <p className="text-base text-center text-green-500 md:text-lg md:mx-3 md:mr-5 leading-6 md:leading-7">
                    {props.extra_info}
                </p>
            </div>
            <div className="flex flex-col items-center rounded-lg mx-4 md:mx-3 ">
                {items.length > 0 ? (
                    <p className="text-base text-bold md:text-lg text-white mx-3">
                        Links:
                    </p>
                ) : (
                    <></>
                )}
                {items}
            </div>
        </div>
    );
};

export default ProjectLayout;
