import React from "react";
import { ReactSVG } from "react-svg";
import github from "../../assets/github.svg";
import instagram from "../../assets/instagram.svg";
import linkedin from "../../assets/linkedin.svg";

function Top() {
    return (
        <div className="w-screen h-screen flex flex-col justify-center text-white">
            {/* ============ WavingAlien ============ */}

            {/* ============ WelcomeMessage ============ */}
            <div className="flex flex-col pb-16">
                {/*<p className="text-center pt-20 text-xl">*/}
                {/*    Checkout our products below !*/}
                {/*</p>*/}
                <p className="text-center text-5xl sm:text-7xl py-2">
                       Alien <span id="blink">X</span>
                </p>
                <div
                    className="flex flex-row pt-10 justify-between mx-auto"
                    // id="waving-alien"
                >
                    <div className="flex flex-col">
                        <img
                            src="https://avatars.githubusercontent.com/u/75479923?v=4"
                            className="rounded-full shadow-lg outline outline-current shadow-black w-[20%] h-fit mx-auto hover:outline-blue-400"
                            alt="alien"
                        />

                        <center style={{ fontSize: "30px", fontWeight: "bold", fontFamily: "Arial, sans-serif" }} className="mt-3">
                            Sawan Sihag
                        </center>

                        <div className="flex flex-row mx-auto">
                            <a
                                href="https://github.com/invinciblesihag"
                                target="blank"
                            >
                                <ReactSVG src={github} className="m-2" />
                            </a>
                            <a
                                href="https://www.instagram.com/sawan.x.sihag/"
                                target="blank"
                            >
                                <ReactSVG src={instagram} className="m-2" />
                            </a>
                            <a
                                href="https://www.linkedin.com/in/sawan-sihag-553a4519b/"
                                target="blank"
                            >
                                <ReactSVG src={linkedin} className="m-2" />
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <img
                            src="https://avatars.githubusercontent.com/u/41207152?v=4"
                            className="rounded-full shadow-lg outline outline-current shadow-black w-[20%] h-fit mx-auto hover:outline-yellow-700"
                            alt="alien"
                        />
                        <center style={{ fontSize: "30px", fontWeight: "bold", fontFamily: "Arial, sans-serif" }} className="mt-3">
                            Rahul Saini
                        </center>
                        <div className="flex flex-row mx-auto">
                            <a
                                href="https://www.github.com/dynamicrahulsaini"
                                target="blank"
                            >
                                <ReactSVG src={github} className="m-2" />
                            </a>
                            <a
                                href="https://www.instagram.com/rahul.codered"
                                target="blank"
                            >
                                <ReactSVG src={instagram} className="m-2" />
                            </a>
                            <a
                                href="https://www.linkedin.com/in/dynamicrahulsaini/"
                                target="blank"
                            >
                                <ReactSVG src={linkedin} className="m-2" />
                            </a>
                        </div>
                    </div>
                </div>
                {/* <center class="pt-20">
                    <a href="#projects">
                        <img
                            src="assets/icons8-down-96.png"
                            className="h-[65%]"
                            alt="welcome"
                            // onClick={}
                        />
                    </a>
                </center> */}
                <a className="text-center pt-20 text-xl" href="#projects">
                    Scroll below to see our projects
                </a>
            </div>
        </div>
    );
}

export default Top;
