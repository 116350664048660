import React, { useState } from 'react';
import {Button, CircularProgress, TextField, Typography} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../index.css';

function Delete() {
 const [email, setEmail] = useState('');
 const [otp, setOtp] = useState('');
 const [otpResponse, setOtpResponse] = useState({});
 const [progress, setProgress] = useState(0);
 const [otpButtonEnabled, setOtpEnabled] = useState(true);

 const api_endpoint = "https://notes-fast-2ciujxlp5q-uc.a.run.app"

 const handleSubmit = (e) => {
    e.preventDefault();
    fetch(api_endpoint + '/account/send_otp?email='+email, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) =>{
          if (res.status !== 200){
              toast.error(res.statusText);
          }
          return res.json()})
      .then((data) => {
        if (data.status === "success"){
            setOtpEnabled(false);
            toast.success(data.message);
        }else{
            toast.error(data.message);
        }
        setOtpResponse(data);
      })
      .catch((error) => {
          toast.error("Limit exceeded, Try after an Hour");
        console.error('Error:', error);
      });
 };

 const handleDeleteAccount = (e) => {
     e.preventDefault();
     setProgress(1);
    fetch(api_endpoint + '/account/delete?otp='+otp+'&email='+email, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
          if (res.status !== 200){
              toast.error(res.statusText);
          }
          return res.json();})
      .then((data) => {
          if (data.status === "success"){
            toast.success(data.message);
            setOtpResponse({});
        }else{
            toast.error(data.message);
        }
          setProgress(0);
      })
      .catch((error) => {
        console.error('Error:', error);
        setProgress(0);
      });
 };

 const handleInputChange = (event) => {
    // Allow only numeric input
    const numericInput = event.target.value.replace(/\D/g, '');
    const limitedInput = numericInput.slice(0, 6);
    setOtp(limitedInput);
  };

 return (
    <div className="col-auto m-6 text-white">
      <Typography className="border-l-red-600" variant="h6" textAlign="center">Delete Account</Typography>
      <div className="form-container">
        <form className="row-auto flex justify-center m-6"
            noValidate onSubmit={handleSubmit}>
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{margin: 10, width: 300}}
            disabled={(otpResponse.status === "success")}
            inputProps={{ style: { color: "ghostwhite" } }}
            required
          />
            {otpResponse && otpResponse.status !== "success" && (<Button
              type="submit" color="primary" size="small" variant="contained"
              style={{margin: 12}}
              disavled={(otpButtonEnabled)}
          >
            Get OTP
          </Button>)}
        </form>

        {otpResponse && otpResponse.status === "success" && (
          <form className="row-auto flex justify-center m-6"
              noValidate onSubmit={handleDeleteAccount}>
            <TextField
              label="OTP"
              variant="outlined"
              type="text"
              value={otp}
              onChange={handleInputChange}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { color: "ghostwhite" } }}
              style={{margin: 10}}
              required
            />
            <Button type="submit" variant="contained" color="error" size="small"
             style={{margin: 12}}
             disabled={(otp.length !== 6)}
            >
              Delete Account
            </Button>
          </form>
        )}

        {progress !== 0 && (
          <CircularProgress size={40} thickness={5} color="primary" variant="indeterminate" value={100}
          style={{justifySelf: "center"}}
          />
        )}
      </div>
        <ToastContainer/>
    </div>
 );
}

export default Delete;
